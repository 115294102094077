import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const Tooltip = ({ children, showDelay, containerClass, tip }) => (
  <div data-tip={tip} data-delay-show={showDelay} className={containerClass}>
    <ReactTooltip />
    {children}
  </div>
)

Tooltip.defaultProps = {
  showDelay: '500',
}

Tooltip.propTypes = {
  tip: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  containerClass: PropTypes.string.isRequired,
  showDelay: PropTypes.string,
}

export default Tooltip
